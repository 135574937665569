import { graphql } from "@/gql/__generated__";

export const followOfficeMutation = graphql(`
  mutation FollowOffice($input: FollowOfficeInput!) {
    followOffice(input: $input) {
      office {
        id
      }
    }
  }
`);

export const unfollowOfficeMutation = graphql(`
  mutation UnfollowOffice($input: UnfollowOfficeInput!) {
    unfollowOffice(input: $input) {
      office {
        id
      }
    }
  }
`);
