import { Suspense, useState } from "react";

import {
  ActionIcon,
  Divider,
  Grid,
  Group,
  Indicator,
  Popover,
  SimpleGrid,
  Stack,
  UnstyledButton,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { format } from "date-fns";
import { useRouter } from "next/navigation";

import {
  ArrowForwardIcon,
  Avatar,
  Badge,
  DisLikeIcon,
  EditIcon,
  H2,
  H3,
  HelpIcon,
  LikeIcon,
  LoadingOverlay,
  Paper,
  SafetyCertificateIcon,
  Text,
} from "@/components/ui/elements";

import { ReviewListModal } from "./ReviewListModal";
import { WorkerPointModal } from "./WorkerPointModal";

type Props = {
  profile: {
    imageUrl: string | null;
    lastName: string;
    firstName: string;
    authenticated: boolean;
  } | null;
  usageStatus: {
    workedCount: number;
    absenceCount: number;
    goodReviewCount: number;
    badReviewCount: number;
  } | null;
  points: number;
  viewer: {
    isSuspended: boolean | null;
    suspensionStartDateAt: string | null;
    suspensionEndDateAt: string | null;
  } | null;
};

export function UserInfo({ profile, usageStatus, points, viewer }: Props) {
  const [opened, { close, open }] = useDisclosure();
  const router = useRouter();
  const [reviewListOpened, { close: reviewListClose, open: reviewListOpen }] =
    useDisclosure();
  const [type, setType] = useState<"good" | "bad">("good");
  const handleReviewOpen = (type: "good" | "bad") => {
    setType(type);
    reviewListOpen();
  };
  if (!profile) {
    return (
      <Paper>
        <Grid pb="lg" align="flex-end" gutter={"sm"}>
          <Grid.Col span="auto">
            <Avatar color="gray" variant="outline" />
          </Grid.Col>
          <Grid.Col span="auto">
            <H2>未設定</H2>
          </Grid.Col>
        </Grid>
      </Paper>
    );
  }
  return (
    <Paper>
      <Grid pb="lg" align="flex-end" gutter={"sm"}>
        <Grid.Col span={4}>
          <UnstyledButton onClick={() => router.push("/mypage/edit")}>
            <Indicator
              label={<EditIcon size={12} />}
              size={24}
              position="bottom-end"
              offset={8}
            >
              <Avatar src={profile.imageUrl} color="gray" variant="outline" />
            </Indicator>
          </UnstyledButton>
        </Grid.Col>
        <Grid.Col span="auto">
          {viewer?.isSuspended && (
            <div>
              <Group gap={0}>
                <Badge color="red" size="sm">
                  利用停止中
                </Badge>
                <Popover width={200} position="bottom" withArrow shadow="md">
                  <Popover.Target>
                    <ActionIcon color="gray" variant="transparent">
                      <HelpIcon />
                    </ActionIcon>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <Text fz="sm">
                      利用停止期間中は求人の応募ができません。
                      <br />
                      30日間を経過すると利用停止が解除され、その後は持ち点が5ptから開始となります。
                    </Text>
                  </Popover.Dropdown>
                </Popover>
              </Group>

              <Text c="red" fz={15} fw="bold">
                {format(
                  new Date(viewer.suspensionStartDateAt ?? ""),
                  "yyyy/MM/dd",
                )}
                ～
                {format(
                  new Date(viewer.suspensionEndDateAt ?? ""),
                  "yyyy/MM/dd",
                )}
              </Text>
            </div>
          )}
          {profile ? (
            <H2 fz={25}>
              {profile.lastName}　{profile.firstName}
            </H2>
          ) : (
            <H2>未設定</H2>
          )}
          {profile.authenticated && (
            <Group gap="xs">
              <SafetyCertificateIcon />
              <Text c="teal" fw="bold">
                本人確認済
              </Text>
            </Group>
          )}
        </Grid.Col>
      </Grid>
      <Divider />
      <Stack pt="lg">
        <H3 ta="center">事業所からの評価</H3>
        <Suspense fallback={<LoadingOverlay />}>
          <ReviewListModal
            type={type}
            opened={reviewListOpened}
            close={reviewListClose}
          />
        </Suspense>
        <SimpleGrid cols={2}>
          <UnstyledButton onClick={() => handleReviewOpen("good")}>
            <Stack align="center" gap="xs">
              <Group>
                <LikeIcon color="teal" size={35} />
                <Text fz={25} fw="bold">
                  {usageStatus?.goodReviewCount}
                </Text>
              </Group>
              <div>
                <Text c="dimmed" fz="md" fw="bold" ta="center">
                  good数
                </Text>
                <Text fz="xs" c="dimmed" td="underline" ta="center">
                  詳細
                  <ArrowForwardIcon
                    style={{ paddingTop: "3px" }}
                    size={"1em"}
                  />
                </Text>
              </div>
            </Stack>
          </UnstyledButton>
          <UnstyledButton onClick={() => handleReviewOpen("bad")}>
            <Stack align="center" gap="xs">
              <Group>
                <DisLikeIcon color="red" size={35} />
                <Text fz={25} fw="bold">
                  {usageStatus?.badReviewCount}
                </Text>
              </Group>
              <div>
                <Text c="dimmed" fz="md" fw="bold" ta="center">
                  bad数
                </Text>
                <Text fz="xs" c="dimmed" td="underline" ta="center">
                  詳細
                  <ArrowForwardIcon
                    style={{ paddingTop: "3px" }}
                    size={"1em"}
                  />
                </Text>
              </div>
            </Stack>
          </UnstyledButton>
        </SimpleGrid>
        <Divider mx="auto" w="80%" />
        <SimpleGrid cols={2}>
          <Stack align="center" gap="xs">
            <Text fz={25} fw="bold">
              {usageStatus?.workedCount}回
            </Text>
            <Text c="dimmed" fz="sm" fw="bold">
              勤務数
            </Text>
          </Stack>
          <Stack align="center" gap="xs">
            <Text fz={25} fw="bold">
              {viewer?.isSuspended ? 0 : points} pt
            </Text>
            <div>
              <Text c="dimmed" ta="center" fz="sm" fw="bold">
                持ち点
              </Text>
              <UnstyledButton onClick={open}>
                <Group gap={0}>
                  <HelpIcon color="teal" />
                  <Text td="underline" c="teal" ta="center" fz="xs">
                    持ち点とは？
                  </Text>
                </Group>
              </UnstyledButton>
              <WorkerPointModal opened={opened} close={close} />
            </div>
          </Stack>
        </SimpleGrid>
      </Stack>
    </Paper>
  );
}
