import React, { useRef, useEffect, useState } from "react";
import { Control, FieldValues, Path } from "react-hook-form";

import {
  ActionIcon,
  Divider,
  ScrollArea,
  Transition,
  rem,
} from "@mantine/core";
import { useOs } from "@mantine/hooks";

import {
  ArrowDownIcon,
  Button,
  H2,
  Paper,
  Stack,
} from "@/components/ui/elements";
import { TextAreaField } from "@/components/ui/parts";

import { MessageItem } from "./MessageItem";
type MessageItemProps = {
  id: string;
  body: string;
  senderType: string;
  sentAt: string;
  isReceivedMessage: boolean;
};

type MessageProps<FORM_TYPE extends FieldValues> = {
  to: string;
  messages: MessageItemProps[];
  control: Control<FORM_TYPE>;
  onSubmit: () => void;
};

export function Message<FORM_TYPE extends FieldValues>({
  to,
  messages,
  control,
  onSubmit,
}: MessageProps<FORM_TYPE>) {
  const viewport = useRef(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const os = useOs();
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    if (os === "ios") {
      // iOSの場合、scrollIntoViewの代わりにwindow.scrollToを使用
      const rect = bottomRef.current?.getBoundingClientRect();
      if (rect) {
        window.scrollTo(0, rect.top);
      }
    } else {
      // iOS以外の場合、scrollIntoViewを使用
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleScroll = () => {
    console.log("scroll");
    if (viewport.current) {
      const { scrollTop, scrollHeight, clientHeight } = viewport.current;
      setShowScrollButton(scrollTop + clientHeight < scrollHeight);
    }
  };

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Stack>
      <Paper>
        <Stack>
          <H2>{to}とのメッセージ</H2>
          <Divider />
          <ScrollArea
            id="scroll-area"
            h={`calc(95vh - (${rem(160)} * 2) )`}
            viewportRef={viewport}
            onScrollPositionChange={handleScroll}
            style={{ position: "relative" }}
          >
            <Stack p="sm">
              {messages.map((message) => (
                <MessageItem key={message.id} {...message} />
              ))}
            </Stack>
            <div style={{ position: "absolute", bottom: 10, right: 20 }}>
              <Transition transition="slide-up" mounted={showScrollButton}>
                {(transitionStyles) => (
                  <ActionIcon style={transitionStyles} onClick={scrollToBottom}>
                    <ArrowDownIcon size={20} />
                  </ActionIcon>
                )}
              </Transition>
            </div>
            <div ref={bottomRef}></div>
          </ScrollArea>

          <TextAreaField
            minRows={1}
            maxRows={4}
            autosize
            name={"newMessage" as Path<FORM_TYPE>}
            control={control}
          />
          <Button type="submit" onClick={onSubmit}>
            送信
          </Button>
        </Stack>
      </Paper>
    </Stack>
  );
}
